import React from 'react';

import Box from '@mui/material/Box';

import Header from '../layout/Header';

const expansionExplorer = () => {
  return (
    <>
      <Header />
      <Box className='Dashboard'>
        {/* <div className="Dashboard--main"> */}
        {/* <div className="Dashboard--wrapper"> */}
        {/* <div className="Dashboard--inner"> */}
        <div className='Dashboard--grid'>
          <div className='Dashboard--item Dashboard--item--sidebar'>
            {/* <Sidebar /> */}
          </div>
          <div
            className='Dashboard--item Dashboard--item--content'
            // style={{ padding: "20px 101px 68px 53px" }}
            style={{ paddingTop: '20px' }}
          >
            <h1 style={{ fontSize: '24px' }}>Expansion Explorer</h1>
            <p style={{ lineHeight: '40px' }}>
              Expansion Explorer shows you areas that have high forecasted
              population growth over the next 5 years. Data is updated
              regularly, and takes into account the efects of COVID19 on
              population and demographic changes. Zooming out on the map will
              cause the expansion overlay to disappear. To restore it, zoom back
              in. When dragging the map, please be patient especially if your
              internet connection is slow. The map is loading a large amount of
              data.
            </p>
            <iframe
              style={{
                width: '100%',
                height: '100vh',
                frameborder: '0',
                marginwidth: '0',
                marginheight: '0',
                scrolling: 'no',
              }}
              title='Expansion Explorer Map'
              src='https://leeripma1.maps.arcgis.com/apps/webappviewer/index.html?id=b676562859c24d099f4996e36b39c472'
            ></iframe>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </Box>
    </>
  );
};

export default expansionExplorer;
