import React from 'react';

const Hamburger = ({ isOpen, headerFlag }) => {
  const containerStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'end',
    gap: '5px',
  };
  const lineStyle = {
    height: '3px',
    backgroundColor: '#fff',

    transition: 'all 0.3s ease',
    borderRadius: '5px',
  };
  const closedStyle1 = {
    backgroundColor: headerFlag ? '#000' : '#9E9E9E',
    transform: isOpen ? 'rotate(45deg) translate(4px,2px)' : 'none',
  };
  const closedStyle2 = {
    backgroundColor: headerFlag ? '#000' : '#9E9E9E',
    transform: isOpen ? 'rotate(-45deg) translate(3px,-2px)' : 'none',
  };

  return (
    <div style={containerStyle}>
      <div style={{ ...lineStyle, width: '100%', ...closedStyle1 }}></div>
      <div style={{ ...lineStyle, width: '100%', ...closedStyle2 }}></div>
    </div>
  );
};

export default Hamburger;
