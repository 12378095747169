import React from 'react';
import { useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import { ReactComponent as DeleteIconRed } from '@/../../public/deleteIconRed.svg';

const VestmapLoader = props => {
  const { _id, status, bedroom, address } = props.loader;
  const [loader, setLoader] = useState(false);

  async function handleDeleteLoader(id) {
    try {
      setLoader(true);

      await props.deleteLoader(id);

      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  return (
    <Box
      key={props.id}
      sx={{
        alignSelf: 'center',
        '@media (max-width: 600px)': {
          width: '100%',
        },
        '@media only screen and (min-width: 600px) and (max-width: 1023px)': {
          width: '49%',
        },
        '@media only screen and (min-width: 1024px)': {
          width: '32%',
        },
        borderRadius: '1.21144rem',
        overflow: 'hidden',
        display: 'flex',

        flexDirection: 'column',
        marginY: '1rem',
        boxShadow: '5px 20px 30px 0px rgba(229, 229, 229,0.7)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '150px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={require('../../img/defaultMap.png')}
          alt='Vestmap Default Map'
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '1.25rem 0',
          textAlign: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='h3'
          textAlign={'center'}
          sx={{
            fontFamily: 'Lato',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
            paddingX: '1rem',
          }}
        >
          {address}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginY: '1.5rem',
          gap: '16px',
        }}
      >
        {status === 'failed' && (
          <>
            <Button
              variant='outlined'
              size='large'
              color='error'
              startIcon={<ReplayIcon />}
              onClick={() =>
                props.computeVestmap(address, bedroom, props.loader)
              }
              sx={{
                color: '#D48B00',
                textTransform: 'capitalize',
                fontFamily: 'Lato',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                border: '2px solid #D48B00',
                borderRadius: '12px',
                fontWeight: '600',
                lineHeight: '150%',
                height: '3rem',
                marginY: '1.5rem',
                '&:hover': {
                  bgcolor: '#D48B00',
                  border: '2px solid #D48B00',
                  color: '#FFF',
                },
              }}
            >
              Try Again
            </Button>

            <Box
              sx={{
                width: '52px',
                height: '3rem',
                border: '2px solid #D32F2F',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              {loader ? (
                <CircularProgress size={20} color='error' />
              ) : (
                <DeleteIconRed onClick={() => handleDeleteLoader(_id)} />
              )}
            </Box>
          </>
        )}
        {status === 'active' && <CircularProgress />}
      </Box>
    </Box>
  );
};

export default VestmapLoader;
