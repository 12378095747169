import React from 'react';
import { useState } from 'react';
import Header from '../../layout/Header';
import { Container, Typography, Box } from '@mui/material';

const Content = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  function setSideBarState(state) {
    setIsSideBarOpen(state);
  }

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          paddingX: {
            md: '1.6%',
            xs: '0',
          },
        }}
      >
        <Header sideBarState={setSideBarState} pageTitle={'Explore Markets'} />
      </Box>

      {/* Content */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '1rem',
          border: '1px solid #EDF2F7',
          background: '#FFF',
          padding: '1.5rem',
          marginX: '3%',
          marginTop: '2%',
          gap: '1.5rem',
        }}
      >
        <Typography
          sx={{
            color: '#1A202C',
            fontFamily: 'Lato',
            fontSize: '1.125rem',
            fontStyle: 'normal',
            fontWeight: 800,
            lineHeight: '135%' /* 1.51875rem */,
            letterSpacing: '0.0125rem',
          }}
        >
          ZipFinder
        </Typography>

        <iframe
          style={{
            width: '100%',
            height: '100vh',
            border: 'none',
            borderRadius: '.5rem',
          }}
          src='https://leeripma1.maps.arcgis.com/apps/dashboards/2ec1c895cf4145f696de973ec83d55a0'
        ></iframe>
      </Box>
    </Box>
  );
};

export default Content;
