import React, { useEffect } from "react";
import styles from "./ConfirmationOverlay.module.css";
import { useRef, useState } from "react";
import { Typography, Box, Button, TextField, MenuItem } from "@mui/material";
import { Edit } from "@mui/icons-material";

function ConfirmationOverlay({
  closeOverlay,
  csvName,
  csvData,
  sendData,
  csvKeys,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState({
    edit: false,
    index: null,
  });
  const [name, setName] = useState("");
  const [mappingFlag, setMappingFlag] = useState(true);
  const [myFields, setMyFields] = useState([
    "Address",
    "City",
    "State",
    "Country",
    "Zipcode",
    "Bedrooms",
  ]);
  const [userFields, setUserFields] = useState([]);
  const [mappings, setMappings] = useState({});

  const ref = useRef();

  useEffect(() => {
    setData(csvData);
    setName(csvName);
    setUserFields(csvKeys);
  }, []);

  // Checks if the field is filled or not
  // True if the field is filled
  function fieldFilled(fieldValue) {
    return fieldValue !== "";
  }

  // Checks if the field is a valid number or not
  // True if the field is a valid number
  function numericFieldValid(fieldValue) {
    return !isNaN(parseInt(fieldValue)) && parseInt(fieldValue) > 0;
  }

  // Checks if all the fields are filled and valid
  // True if all the fields are filled and valid
  function dataIsValid() {
    const allFieldsFilled = data.every((row) =>
      Object.values(row).every((val) => val !== "")
    );
    const allNumericFieldsValid = data.every((row) => {
      const bedrooms = parseInt(row.Bedrooms);
      const zipCode = parseInt(row.Zipcode);

      return !isNaN(bedrooms) && !isNaN(zipCode) && bedrooms > 0 && zipCode > 0;
    });
    if (allFieldsFilled && allNumericFieldsValid) {
      return true;
    } else {
      return false;
    }
  }

  function handleCancel() {
    setIsOpen(false);
    closeOverlay();
  }

  // Structure the data before sending it to the parent component
  function confirmMapping() {
    const mappedData = data.map((row) => {
      return [
        row.Zipcode +
          " " +
          row.Address +
          ", " +
          row.City +
          ", " +
          row.State +
          ", " +
          row.Country,
        parseInt(row.Bedrooms),
      ];
    });

    console.log({ mappedData });

    sendData(name, mappedData); // ! Uncomment this line to send the data to the parent component

    setIsOpen(false);
    closeOverlay();
  }

  const handleChange = (index, key, value) => {
    const newData = [...data];
    newData[index][key] = value;
    setData(newData);
  };

  const handleMappingChange = (myField, userField) => {
    const newMappings = { ...mappings };
    newMappings[myField] = userField;
    setMappings(newMappings);
  };

  // Structure data before validating interface
  const prepareData = () => {
    const data = csvData.map((row) => {
      return {
        Address: row[mappings["Address"]],
        City: row[mappings["City"]],
        State: row[mappings["State"]],
        Country: row[mappings["Country"]],
        Zipcode: row[mappings["Zipcode"]],
        Bedrooms: row[mappings["Bedrooms"]],
      };
    });

    return data;
  };

  const validateMappings = () => {
    const mappedFields = Object.values(mappings);

    return new Set(mappedFields).size === mappedFields.length;
  };

  const onEdit = (state, index) => {
    console.log({ state, index });
    setEdit({
      edit: state,
      index: index,
    });
  };

  const headingStyle = {
    width: "16%",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "1.2rem",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    width: "16%",
    justifyContent: "space-between",
  };

  return (
    <div id="backgroundArea" className={styles.backgroundArea}>
      <dialog
        id="confirmationOverlay"
        ref={ref}
        open={isOpen}
        className={isOpen ? styles.confirmationPopUp : ""}
      >
        {mappingFlag ? (
          <Box className={styles.confirmationOverlayContent}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Typography variant="h5" component="h2">
                Mapping User Fields
              </Typography>
              <Button
                onClick={handleCancel}
                variant="outlined"
                sx={{
                  color: "#39585c",
                  borderColor: "#39585c",
                  ":hover": {
                    color: "#39585c",
                    borderColor: "#39585c",
                  },
                }}
              >
                Close
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "50vh",
                scrollbarWidth: "none",
              }}
            >
              {myFields.map((field, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      paddingX: "1rem",
                      margin: "1rem",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6">{field}</Typography>
                    <TextField
                      select
                      label="Select"
                      value={mappings[field] || ""}
                      size="small"
                      sx={{
                        width: "50%",
                        marginRight: "25%",
                        // border: !mappings[field]
                        //   ? "1px solid red"
                        //   : "1px solid #39585c",
                        // borderRadius: "4px",

                        alignSelf: "self-end",
                      }}
                      required
                      onChange={(e) =>
                        handleMappingChange(field, e.target.value)
                      }
                      helperText="Please select your field"
                    >
                      {userFields.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          // disabled={
                          //   Object.values(mappings).includes(option) &&
                          //   mappings[field] !== option
                          // }
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                );
              })}
            </Box>
            <Button
              variant="contained"
              disabled={!validateMappings() || Object.keys(mappings).length < 6}
              sx={{
                backgroundColor: "#39585c",
                ":hover": {
                  backgroundColor: "#39585c",
                },
                marginTop: "4%",
                marginBottom: "2%",
              }}
              onClick={() => {
                console.log({ mappings });
                const mappedData = prepareData();
                console.log({ mappedData });

                setData(mappedData);

                setMappingFlag(false);
              }}
            >
              Confirm Mapping
            </Button>
          </Box>
        ) : (
          <Box className={styles.confirmationOverlayContent}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
              }}
            >
              <Typography variant="h5" component="h2">
                Validate Data
              </Typography>
              <Button
                onClick={handleCancel}
                variant="outlined"
                sx={{
                  color: "#39585c",
                  borderColor: "#39585c",
                  ":hover": {
                    color: "#39585c",
                    borderColor: "#39585c",
                  },
                }}
              >
                Close
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  textAlign: "left",
                  paddingX: "1rem",
                }}
              >
                {name}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
                marginX: "auto",
                paddingTop: "1rem",
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: "50vh",
                scrollbarWidth: "none",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: "#39585c",
                  padding: "0.5rem",
                  color: "white",
                  borderRadius: "0.5rem",
                }}
              >
                <Typography
                  sx={{
                    width: "2%",
                  }}
                >
                  #
                </Typography>

                <Typography sx={headingStyle}>Address</Typography>
                <Typography sx={headingStyle}>City</Typography>
                <Typography sx={headingStyle}>State</Typography>
                <Typography sx={headingStyle}>Country</Typography>
                <Typography sx={headingStyle}>Zipcode</Typography>
                <Typography sx={headingStyle}>Bedrooms</Typography>
                <Box
                  sx={{
                    width: "2.4%",
                    height: "100%",
                    marginLeft: "1%",
                  }}
                ></Box>
              </Box>

              {data.map((row, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "0.5rem",

                    borderBottom: "1px solid #39585c",
                    borderRadius: "0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      width: "2%",
                    }}
                  >
                    {index + 1}
                  </Typography>

                  <Box sx={rowStyle}>
                    {edit?.edit && edit?.index === index ? (
                      <TextField
                        size="small"
                        value={row.Address}
                        sx={{
                          border: !fieldFilled(row.Address)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                        onChange={(e) =>
                          handleChange(index, "Address", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: "100%",

                          textAlign: "left",
                          border: !fieldFilled(row.Address)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                      >
                        {row.Address}
                      </Typography>
                    )}
                  </Box>

                  {/* City */}
                  <Box sx={rowStyle}>
                    {edit?.edit && edit?.index === index ? (
                      <TextField
                        size="small"
                        value={row.City}
                        sx={{
                          border: !fieldFilled(row.City)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                        onChange={(e) =>
                          handleChange(index, "City", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: "100%",

                          textAlign: "left",
                          border: !fieldFilled(row.City)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                      >
                        {row.City}
                      </Typography>
                    )}
                  </Box>

                  {/* State */}
                  <Box sx={rowStyle}>
                    {edit?.edit && edit?.index === index ? (
                      <TextField
                        size="small"
                        value={row.State}
                        sx={{
                          border: !fieldFilled(row.State)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                        onChange={(e) =>
                          handleChange(index, "State", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: "100%",

                          textAlign: "left",
                          border: !fieldFilled(row.State)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                      >
                        {row.State}
                      </Typography>
                    )}
                  </Box>

                  {/* Country */}
                  <Box sx={rowStyle}>
                    {edit?.edit && edit?.index === index ? (
                      <TextField
                        size="small"
                        value={row.Country}
                        sx={{
                          border: !fieldFilled(row.Country)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                        onChange={(e) =>
                          handleChange(index, "Country", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: "100%",

                          textAlign: "left",
                          border: !fieldFilled(row.Country)
                            ? "1px solid red"
                            : "none",

                          borderRadius: "4px",
                        }}
                      >
                        {row.Country}
                      </Typography>
                    )}
                  </Box>

                  {/* Zipcode */}
                  <Box sx={rowStyle}>
                    {edit?.edit && edit?.index === index ? (
                      <TextField
                        size="small"
                        value={row.Zipcode}
                        sx={{
                          border:
                            !fieldFilled(row.Zipcode) ||
                            !numericFieldValid(row.Zipcode)
                              ? "1px solid red"
                              : "none",

                          borderRadius: "4px",
                        }}
                        onChange={(e) =>
                          handleChange(index, "Zipcode", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: "100%",

                          textAlign: "left",
                          border:
                            !fieldFilled(row.Zipcode) ||
                            !numericFieldValid(row.Zipcode)
                              ? "1px solid red"
                              : "none",

                          borderRadius: "4px",
                        }}
                      >
                        {row.Zipcode}
                      </Typography>
                    )}
                  </Box>

                  {/* Bedrooms */}
                  <Box sx={rowStyle}>
                    {edit?.edit && edit?.index === index ? (
                      <TextField
                        size="small"
                        value={row.Bedrooms}
                        sx={{
                          border:
                            !fieldFilled(row.Bedrooms) ||
                            !numericFieldValid(row.Bedrooms)
                              ? "1px solid red"
                              : "none",

                          borderRadius: "4px",
                        }}
                        onChange={(e) =>
                          handleChange(index, "Bedrooms", e.target.value)
                        }
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: "100%",
                          // paddingX: "5rem",
                          textAlign: "center",
                          border:
                            !fieldFilled(row.Bedrooms) ||
                            !numericFieldValid(row.Bedrooms)
                              ? "1px solid red"
                              : "none",

                          borderRadius: "4px",
                        }}
                      >
                        {row.Bedrooms}
                      </Typography>
                    )}
                  </Box>

                  <Edit
                    sx={{
                      color: "#39585c",
                      cursor: "pointer",
                      marginLeft: "1%",
                    }}
                    onClick={() => onEdit(!edit.edit, index)}
                  />
                </Box>
              ))}
            </Box>

            <Button
              variant="contained"
              disabled={!dataIsValid()}
              sx={{
                position: "absolute",
                bottom: "1rem",
                backgroundColor: "#39585c",
                ":hover": {
                  backgroundColor: "#39585c",
                },

                // * To set the disabled button style
                // ":disabled": !dataIsValid()
                //   ? { backgroundColor: "#39585c", opacity: "0.5" }
                //   : { backgroundColor: "#39585c" },
              }}
              onClick={confirmMapping}
            >
              Confirm
            </Button>
          </Box>
        )}
      </dialog>
    </div>
  );
}

export default ConfirmationOverlay;
