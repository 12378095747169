import logo from "./../../img/logo.png";
import {React} from 'react'
import { NavLink } from "react-router-dom";

const NotFound = () => {

    return (
        <div className="loginScren">
            <div className="loginScrenInner">
                <img src={logo} alt="Logo" />
                <p className="loginScrenTitle">404 Page Not Found</p>
                <div className="loginScrenButtons">
                    <NavLink to="/">
                        <button className="loginScrenButtonLogin">Home</button>
                    </NavLink>
                </div>
                <p className="loginScrenCopyright">All Copy Rights 2023 Reserved: VestMap</p>
            </div>
        </div>
    );
}

export default NotFound;