import React from "react";
import styles from "./overlay.module.css";
import data from "./data.json";

const Overlay = ({ isVisible, onClose, keyElement }) => {
  return (
    <>
      {isVisible && (
        <div className={styles.overlay} onClick={onClose}>
          <div className={styles.overlayContent}>
            <div className={styles.closer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x-circle"
                onClick={onClose}
              >
                <circle cx="12" cy="12" r="10" />
                <path d="m15 9-6 6" />
                <path d="m9 9 6 6" />
              </svg>
            </div>
            <p
              style={{
                textDecoration: "none",
                textAlign: "left",
                fontSize: "1rem",
              }}
            >
              <b style={{}}>{data[keyElement].title}</b> <br />
              {data[keyElement].description}
              <a href={data[keyElement].link} target="_blank">
                {data[keyElement].linkText}
                {/* See the Tapestry key&gt;&gt;{' '} */}
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Overlay;
