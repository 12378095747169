import React, { useRef, useState } from "react";
import styles from "./ErrorPopUp.module.css";
import { Button, Typography } from "@mui/material";

function ErrorPopUp({ closeLimitError, error }) {
  const [isOpen, setIsOpen] = useState(true);
  const ref = useRef();

  // ! not needed
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    closeLimitError();
  };
  console.log("[PopUp] error: ", error);

  return (
    <div id="bgArea" className={styles.bgArea}>
      <dialog
        id="errorPopUp"
        ref={ref}
        open={isOpen}
        className={isOpen ? styles.errorPopUp : ""}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0,0,256,256"
          style={{
            fill: "#FA5252",
            marginTop: "1%",
          }}
        >
          <g
            fill="#fa5252"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="1"
            stroke-linecap="butt"
            stroke-linejoin="miter"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style={{
              mixBlendMode: "normal",
            }}
          >
            <g transform="scale(2,2)">
              <path d="M72.2,20.1c-1.7,-3 -4.8,-4.7 -8.2,-4.7c-3.4,0 -6.5,1.8 -8.2,4.7l-53.5,92.7c-1.7,3 -1.7,6.5 0,9.5c1.7,3 4.8,4.7 8.2,4.7h107c3.4,0 6.5,-1.8 8.2,-4.7c1.7,-3 1.7,-6.5 0,-9.5zM67,104c0,1.7 -1.3,3 -3,3c-1.7,0 -3,-1.3 -3,-3v-5.6c0,-1.7 1.3,-3 3,-3c1.7,0 3,1.3 3,3zM67,84c0,1.7 -1.3,3 -3,3c-1.7,0 -3,-1.3 -3,-3v-35.6c0,-1.7 1.3,-3 3,-3c1.7,0 3,1.3 3,3z"></path>
            </g>
          </g>
        </svg>

        <Typography
          variant="h6"
          sx={{
            color: "#FA5252",
            fontWeight: "bold",
          }}
        >
          Error
        </Typography>

        <Typography
          variant="body1"
          sx={{
            marginTop: "1%",
            fontWeight: "lighter",
            color: "#3c3c3c",
            textAlign: "center",
          }}
        >
          {error}
        </Typography>

        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "#FA5252",
            color: "white",
            width: "100%",
            borderRadius: "0px 0px 10px 10px",
            border: "1px solid #FA5252",
            marginTop: "4%",
            "&:hover": {
              backgroundColor: "#FA5252",
              color: "white",
            },
          }}
        >
          Okay
        </Button>
      </dialog>
    </div>
  );
}

export default ErrorPopUp;
