import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Button,
  Snackbar,
  Alert,
  Box,
  Container,
  Skeleton,
  Typography,
} from '@mui/material';
import { useAuth } from '../../../AuthProvider';
import Papa from 'papaparse';
import VestmapViewer from '../../VestmapViewer';
import BulkView from './BulkView';
import ListView from './ListView';
import ErrorPopUp from './ErrorPopUp';
import ConfirmationOverlay from './ConfirmationOverlay';
import Header from '../../layout/Header';
import { ReactComponent as UploadIconLight } from '@/../../public/uploadiconlight.svg';
import { ReactComponent as ArrowsDark } from '@/../../public/arrowsdark.svg';

const breadcrumbStyle = {
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '135%',
  letterSpacing: '0.0125rem',
  textDecoration: 'underline',
  cursor: 'pointer',
};

const customFontStyle = {
  color: '#39585C',
  fontFamily: 'Lato',
  fontStyle: 'normal',
};

const tableHeadingStyle = {
  color: '#004852',
  fontFamily: customFontStyle.fontFamily,
  fontSize: '1rem',
  fontStyle: customFontStyle.fontStyle,
  fontWeight: '800',
  lineHeight: '150%',
  letterSpacing: '0.0125rem',
};

export default function BulkContent(props) {
  const { openSignup } = useAuth();

  const [user, setUser] = useState({});

  const [formData, setFormData] = useState({
    file: null,
    name: '',
    addresses: [],
  });

  // for the list view
  const [listViewState, setListViewState] = useState(false);
  const [listViewData, setListViewData] = useState(null);
  const [listViewBulkName, setListViewBulkName] = useState(null);
  const [bulkStatus, setBulkStatus] = useState(null);
  const [rateError, setRateError] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [csvKeysUser, setCsvKeysUser] = useState([]);
  const [initLoading, setInitLoading] = useState(true);
  const fileInput = useRef();

  function changeListViewState(newState) {
    setListViewState(newState);
  }

  const handleViewChange = (newState, bulkData, groupName, status) => {
    console.log('Clicked on the bulk item');
    console.log({ bulkData });
    setListViewData(bulkData);
    setListViewState(newState);
    setListViewBulkName(groupName);
    setBulkStatus(status);
  };

  const cleanView = () => {
    setListViewData(null);
    setListViewState(false);
    setListViewBulkName(null);
    setBulkStatus(null);
  };

  const sseEventListner = async () => {
    // for sse events
    console.log('SSE Event Listener');
    const sse = new EventSource(
      process.env.REACT_APP_NODE_URL + `/bulk-vestmap/computeSSE`,
    );

    sse.onmessage = event => {
      try {
        const data = JSON.parse(event.data);
        console.log({ data });
        // * type 0 - initial event
        if (data.type == 0) {
          console.log('[SSE Event Alert] - Initial Event');
        }

        // * type 1 - Vestmaps that were just inserted
        if (data.type == 1) {
          console.log('[SSE Event Alert] - Vestmaps Added');
          const insertedVestmaps = data.vestmaps;
          console.log({ insertedVestmaps });

          console.log({ bulkVestmaps });

          setBulkVestmaps(prev => {
            return prev.map(bulk => {
              if (bulk.status === 'Processing...') {
                bulk.bulks = insertedVestmaps;
                return bulk;
              } else return bulk;
            });
          });
        }

        // * type 2 - Vestmap that has been computed
        if (data.type == 2) {
          console.log('[SSE Event Alert] - Vestmap Computed');
          const vestmap = data.vestmap.value;
          console.log({ vestmap });

          setBulkVestmaps(prev => {
            return prev.map(bulk => {
              if (bulk.status === 'Processing...') {
                const newBulks = bulk.bulks.map(item => {
                  if (item._id === vestmap._id) {
                    return vestmap;
                  } else return item;
                });
                bulk.bulks = newBulks;

                return bulk;
              } else return bulk;
            });
          });
        }

        // to handle the event computationCompleted i.e. close the connection
        if (data.type == 3) {
          console.log('[SSE Event Alert] - Close Connection Event');
          sse.close();
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    sse.onerror = err => {
      console.log(err);
    };
  };

  const sseSyncStartupRoutine = async user => {
    // for sse events
    console.log('SSE Event Listener');
    const sse = new EventSource(
      process.env.REACT_APP_NODE_URL +
        `/bulk-vestmap/syncSSE?userID=${user._id}`,
    );

    sse.onmessage = async event => {
      try {
        const data = JSON.parse(event.data);
        console.log({ data });
        // * type 0 - initial event
        if (data.type == 0) {
          console.log('[SSE Event Alert] - Initial Event');
        }

        // * type 4 - All vestmaps completed
        if (data.type == 4) {
          console.log('[SSE Event Alert] - All Vestmaps Completed');
          sse.close();
          console.log('SSE Connection Closed');
          startingRoutine(user);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
  };

  // const [bulkVestmaps, setBulkVestmaps] = useState([tester]);
  const [bulkVestmaps, setBulkVestmaps] = useState([]);
  const [allAddresses, setAllAddresses] = useState([]);
  const [currentVestmap, setCurrentVestmap] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const sseConnectionRef = useRef(false);
  const [sortOrder, setSortOrder] = useState(true); // false for descending, true for ascending
  const [previousSortKey, setPreviousSortKey] = useState('date');

  const [loading, setLoading] = useState(false);

  function deleteBulk(bulkID) {
    setBulkVestmaps(prev => {
      return prev.filter(bulk => bulk.ID !== bulkID);
    });
  }

  const [csvContent, setCsvContent] = useState([]);

  // * for our csv file format
  const parseFile = () => {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      });
      const parsedData = csv?.data;

      // * Max 50 addresses limit
      if (parsedData.length > 50) {
        setErrorString('Please upload a csv with 50 or less addresses.');
        setRateError(true);
        return;
      }

      // check remaining bulk searches from local storage
      if (localStorage) {
        const user = JSON.parse(localStorage.getItem('mongodbUser') || {})[0];

        // * In case of old users who don't have bulk_searches_remaining
        if (user.bulk_searches_remaining) {
          const remainingBulkSearches = user.bulk_searches_remaining;
          console.log({ remainingBulkSearches });

          if (!(remainingBulkSearches > 0)) {
            setErrorString('You have no bulk searches remaining.');
            setRateError(true);
            return;
          }
        }
      }

      const addressBedroomTuple = parsedData.map(row => {
        if (
          row.Address !== undefined &&
          row.Bedrooms !== undefined &&
          row.City !== undefined &&
          row.State !== undefined &&
          row.Zipcode !== undefined &&
          row.Country !== undefined
        ) {
          return {
            Zipcode: row.Zipcode,
            Address: row.Address,
            City: row.City,
            State: row.State,
            Country: row.Country,
            Bedrooms: parseInt(row.Bedrooms),
          };
        }
      });

      const res = addressBedroomTuple.filter(Boolean);

      setCsvContent(prev => {
        return res;
      });
    };
    console.log({ formData });
    console.log({ csvContent });
    reader.readAsText(formData.file);
  };

  // * for any type of csv file format
  const parseFile2 = () => {
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
      });
      const parsedData = csv?.data;

      // ? Checks
      // * Max 50 addresses limit
      if (parsedData.length > 50) {
        setErrorString('Please upload a csv with 50 or less addresses.');
        setRateError(true);
        return;
      }

      // * Check remaining bulk searches from local storage
      if (localStorage) {
        const user = JSON.parse(localStorage.getItem('mongodbUser') || {});

        // * In case of old users who don't have bulk_searches_remaining
        if (user.bulk_searches_remaining) {
          const remainingBulkSearches = user.bulk_searches_remaining;
          console.log({ remainingBulkSearches });

          if (!(remainingBulkSearches > 0)) {
            setErrorString('You have no bulk searches remaining.');
            setRateError(true);
            return;
          }
        }
      }

      const trimmedData = parsedData.filter(row =>
        Object.values(row).some(value => value.trim() !== ''),
      );

      setCsvKeysUser(prev => {
        return Object.keys(trimmedData[0]);
      });
      setCsvContent(prev => {
        return trimmedData;
      });
    };
    reader.readAsText(formData.file);
  };

  // when csvContent is updated (from the parseFile function)
  useEffect(() => {
    if (csvContent.length > 0) {
      setConfirmationOverlay(true);
      console.log('Opening Confirmation Overlay');
      // RecordCreate();
    }
  }, [csvContent]);

  const RecordCreate = async addresses => {
    if (loading) return;

    const fetchResponse = async addresses => {
      console.log({ addresses });
      let errorFlag = false;
      addresses.forEach(address => {
        if (address[1] < 1 || address[1] > 4) errorFlag = true;
      });

      if (errorFlag) {
        setErrorString(
          'Please enter a valid number of bedrooms between 1 and 4.',
        );
        setRateError(true);
        return;
      }

      setLoading(true);
      const ID = Math.floor(Math.random() * 1000000);
      const bulks = addresses.map(address => {
        return {
          address,
          status: 'active',
        };
      });
      const loaderRecord = {
        ID,
        groupName: formData.name,
        bulks,
        status: 'Processing...',
      };

      setBulkVestmaps(prev => {
        return [...prev, loaderRecord];
      });

      sseConnectionRef.current = true;

      try {
        const url = process.env.REACT_APP_NODE_URL + `/bulk-vestmap/compute`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            addresses,
            name: formData.name,
            userID: user._id,
          }),
        });

        const responseData = await response.json();
        if (response.ok) {
          sseConnectionRef.current = false;
          setBulkVestmaps(prev => {
            return prev.map(bulk => {
              if (bulk.ID === ID) {
                responseData.response['status'] = 'Completed';
                return responseData.response;
              } else return bulk;
            });
          });

          if (localStorage) {
            console.log('Local Storage is available');
            const user = JSON.parse(
              localStorage.getItem('mongodbUser') || {},
            )[0];
            console.log(user.bulk_searches_remaining);

            // * In case of old users who don't have bulk_searches_remaining
            if (user.bulk_searches_remaining) {
              user.bulk_searches_remaining = user.bulk_searches_remaining - 1;
              localStorage.setItem('mongodbUser', JSON.stringify([user]));
            }
          }
        } else {
          console.log(response.status);

          // * No bulk searches remaining
          if (response.status == 400) {
            console.log('400 error');
            setErrorString('You have no bulk searches remaining.');
            setRateError(true);

            setBulkVestmaps(prev => {
              return prev.map(bulk => {
                if (bulk.ID === ID) return { ...bulk, status: 'Failed' };
                else return bulk;
              });
            });
            return;
          }
          // * Plan expired
          else if (response.status == 401) {
            console.log('401 error');
            setErrorString('Your plan has expired.');
            setRateError(true);

            setBulkVestmaps(prev => {
              return prev.map(bulk => {
                if (bulk.ID === ID) return { ...bulk, status: 'Failed' };
                else return bulk;
              });
            });
            return;
          }

          // in case error is not 400
          setBulkVestmaps(prev => {
            return prev.map(bulk => {
              if (bulk.ID === ID) {
                switch (responseData.response.status) {
                  case 'failed':
                    responseData.response['status'] = 'Failed';
                    return responseData.response;

                  case 'partially completed':
                    responseData.response['status'] = 'Partially Completed';
                    return responseData.response;

                  default:
                    return bulk;
                }
              } else return bulk;
            });
          });
        }
      } catch (err) {
        console.log(err);

        setBulkVestmaps(prev => {
          return prev.map(bulk => {
            if (bulk.ID === ID) return { ...bulk, status: 'Error' };
            else return bulk;
          });
        });
      } finally {
        setLoading(false);
      }
    };

    fetchResponse(addresses);
  };

  const handleProSearchesExpended = (state, errorString) => {
    setRateError(state);
    setErrorString(errorString);
  };

  useEffect(() => {
    if (sseConnectionRef.current === true) {
      console.log('SSE Connection is true');
      sseEventListner();
    }
  }, [sseConnectionRef.current]);

  // run when a file is selected
  const handleFileChange = event => {
    setRateError(false);
    const file = event.target.files[0];
    const filename = file.name;
    if (file) setFormData({ ...formData, file: file, name: filename });
  };

  const [confirmationOverlay, setConfirmationOverlay] = useState(false);

  // run when the formData is updated
  useEffect(() => {
    // Check if formData is not empty before running the effect
    if (formData && formData.file && formData.name) {
      console.log('handleFileChange FormData');
      console.log(formData);

      // Check if RecordCreate is a function before calling it
      if (typeof RecordCreate === 'function') {
        // parseFile();
        parseFile2();
      }
    }
  }, [formData]);

  const viewVestmap = vestmap => {
    console.log('This function is called');
    console.log({ vestmap }); //should be an object

    setCurrentVestmap(vestmap);
    setOpenModal(true);
  };

  const startingRoutine = async user => {
    const url = process.env.REACT_APP_NODE_URL + `/bulk-vestmap/fetch-all`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID: user._id }),
    });
    const responseData = await response.json();
    if (response.ok) {
      // setBulkVestmaps(responseData.response);
      console.log('[Initial Fetch]');

      setBulkVestmaps(prev => {
        return responseData.response.map(bulk => {
          switch (bulk.status) {
            case 'completed':
              return { ...bulk, status: 'Completed' };
            case 'failed':
              return { ...bulk, status: 'Failed' };
            case 'active':
              sseSyncStartupRoutine(user);
              return { ...bulk, status: 'Processing...' };
            case 'partially completed':
              return { ...bulk, status: 'Partially Completed' };
            default:
              return bulk;
          }
        });
      });

      setInitLoading(false);
    }
  };

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("mongodbUser") || {});
    let user = JSON.parse(localStorage.getItem('mongodbUser') || {});

    if (Array.isArray(user)) {
      console.log('User is an array');
      user = user[0];
    }

    setUser(user);
    startingRoutine(user);
    console.log({ user });
  }, []);

  // For SnackBar
  const [successOpen, setSuccessOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false);

  const openSnackbar = status => {
    console.log(status);
    if (status === 'success') {
      setSuccessOpen(true);
    } else {
      setFailOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setRateError(false);
    setFailOpen(false);
  };

  const modifyVestmap = vestmap => {
    console.log({ vestmap });

    setBulkVestmaps(prev => {
      return prev.map(bulk => {
        if (bulk.ID === vestmap.ID) {
          return vestmap;
        } else return bulk;
      });
    });
  };

  function closeLimitError() {
    setRateError(false);
  }

  function closeConfirmationOverlay() {
    setConfirmationOverlay(false);
  }

  // * Function to receive data from the ConfirmationOverlay and create a new record
  function receiveData(name, data) {
    console.log({ name });
    console.log({ data });

    RecordCreate(data);
  }

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  function setSideBarState(state) {
    setIsSideBarOpen(state);
  }

  const sortBulks = key => {
    if (key !== previousSortKey) {
      setSortOrder(true); // Set sortOrder to true initially for a new key
      setPreviousSortKey(key);
    } else {
      setSortOrder(prevSortOrder => !prevSortOrder);
    }

    const sortedBulks = [...bulkVestmaps].sort((a, b) => {
      switch (key) {
        case 'date':
          return sortOrder ? a.date - b.date : b.date - a.date;
        case 'groupName':
          return sortOrder
            ? a.groupName.localeCompare(b.groupName)
            : b.groupName.localeCompare(a.groupName);
        case 'totalReports':
          return sortOrder
            ? a.addresses.length - b.addresses.length
            : b.addresses.length - a.addresses.length;
        case 'status':
          return sortOrder
            ? a.status.localeCompare(b.status)
            : b.status.localeCompare(a.status);
        default:
          return 0;
      }
    });

    setBulkVestmaps(sortedBulks);
  };

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          paddingX: {
            md: '1.6%',
            xs: '0',
          },
        }}
      >
        <Header sideBarState={setSideBarState} pageTitle={'Bulk Imports'} />
      </Box>
      <Snackbar
        open={successOpen}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          Success!
        </Alert>
      </Snackbar>
      <Snackbar open={failOpen} autoHideDuration={1000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='error' sx={{ width: '100%' }}>
          Sorry an error occured. Please try again later.
        </Alert>
      </Snackbar>

      {rateError && (
        <ErrorPopUp closeLimitError={closeLimitError} error={errorString} />
      )}

      {confirmationOverlay && (
        <ConfirmationOverlay
          closeOverlay={closeConfirmationOverlay}
          csvData={csvContent}
          csvName={formData.name}
          sendData={receiveData}
          csvKeys={csvKeysUser}
        />
      )}
      <Box
        sx={{
          flexShrink: 0,
          paddingY: '1rem',
          paddingX: '2.7%',
          transition: 'all 0.3s ease',
          transform:
            isSideBarOpen == true ? 'translateX(60%)' : 'translateX(0)',
        }}
      >
        <Container
          sx={{
            display: {
              md: 'none',
              xs: 'flex',
            },
            paddingBottom: '1rem',
          }}
        >
          <Typography
            sx={{
              color: '#39585C',
              fontFamily: 'Lato',
              fontSize: '1.5rem',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: '130%',
            }}
          >
            Bulk Imports
          </Typography>
        </Container>
        <Box
          sx={{
            display: 'flex',
            padding: '1.5rem',
            flexDirection: 'column',
            gap: '1rem',
            borderRadius: '1rem',
            border: '1px solid #EDF2F7',
          }}
        >
          <Typography
            sx={[
              customFontStyle,
              {
                fontSize: '1.125rem',
                fontWeight: '800',
                lineHeight: '135%',
                letterSpacing: '0.0125rem',
              },
            ]}
          >
            Upload File
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',

              height: '3rem',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{
                bgcolor: '#4FA490',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '1rem',
                height: '2.5rem',
                width: '77%',
                height: '100%',
                borderRadius: '0.75rem',
                '&:hover': { bgcolor: '#659f8f' },
                textTransform: 'none',
              }}
              startIcon={<UploadIconLight />}
              onClick={() => fileInput.current.click()}
            >
              <input
                type='file'
                ref={fileInput}
                accept='.csv'
                hidden
                style={{
                  display: 'none',
                }}
                onChange={handleFileChange}
              />
              Upload CSV File
            </Button>
            <Box
              sx={{
                width: 'auto',
                borderRadius: '0.75rem',
                border: '1px solid #EDF2F7',
                display: 'flex',
                padding: '1rem',
                alignItems: 'center',
                width: '18%',
                justifyContent: 'center',
                alignSelf: 'stretch',
              }}
            >
              <Typography
                sx={[
                  customFontStyle,
                  {
                    fontSize: '1rem',
                    fontWeight: '400',

                    lineHeight: '150%',
                    letterSpacing: '0.0125rem',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                ]}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_NODE_URL}/bulk-vestmap/downloadCSV`,
                    '_blank',
                  );
                }}
              >
                Download Sample CSV
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Sub Heading and Tables */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1.5rem',
            gap: '1rem',

            marginTop: '1rem',
          }}
        >
          {/* Sub Headings */}
          <Box
            sx={{
              display: 'inline-flex',

              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '1rem',
            }}
          >
            <Typography
              sx={[
                customFontStyle,
                {
                  fontSize: '1.125rem',
                  fontWeight: '800',
                  lineHeight: '135%',
                  letterSpacing: '0.0125rem',
                },
              ]}
            >
              Bulk Vestmaps
            </Typography>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={[breadcrumbStyle, customFontStyle]}
                onClick={() => {
                  cleanView();
                }}
              >
                All Reports{' '}
              </Typography>
              <Typography
                sx={[
                  breadcrumbStyle,
                  customFontStyle,
                  { textDecoration: 'none' },
                ]}
              >
                &nbsp; &gt; &nbsp;
              </Typography>
              {listViewBulkName && (
                <Typography
                  sx={[
                    breadcrumbStyle,
                    customFontStyle,
                    { textDecoration: 'underline', color: '#4FA490' },
                  ]}
                >
                  {listViewBulkName}
                </Typography>
              )}
            </Box>
          </Box>

          {/* Tables */}

          {listViewState === true ? (
            <ListView
              addresses={listViewData}
              bulkName={listViewBulkName}
              status={bulkStatus}
              viewVestmap={viewVestmap}
              changeListViewState={changeListViewState}
              handleProSearchesExpended={handleProSearchesExpended}
            />
          ) : (
            <>
              <Grid
                container
                sx={{
                  height: '3.5rem',
                  borderTop: '1px solid #EDF2F7',
                }}
              >
                <Grid
                  item
                  xs={2}
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                >
                  <Typography sx={[tableHeadingStyle]}>Date</Typography>
                  <ArrowsDark
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sortBulks('date');
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                >
                  <Typography sx={[tableHeadingStyle]}>Group Name</Typography>
                  <ArrowsDark
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sortBulks('groupName');
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                >
                  <Typography sx={[tableHeadingStyle]}>
                    Total Reports
                  </Typography>
                  <ArrowsDark
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sortBulks('totalReports');
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2.5}
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                >
                  <Typography sx={[tableHeadingStyle]}>Status</Typography>
                  <ArrowsDark
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sortBulks('status');
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  {' '}
                </Grid>
              </Grid>

              {initLoading
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => (
                    <Skeleton
                      key={item}
                      variant='rectangular'
                      height={38}
                      sx={{
                        width: '100%',
                        marginBottom: '0.5rem',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                      }}
                      animation='wave'
                    />
                  ))
                : // Group Name, Date, Total Reports, Status
                  bulkVestmaps.map((item, index) => (
                    <BulkView
                      key={item.ID}
                      date={new Date()}
                      groupName={item.groupName}
                      status={item.status}
                      bulkID={item.ID}
                      deleteBulk={deleteBulk}
                      addresses={item.bulks}
                      viewVestmap={viewVestmap}
                      handleListView={handleViewChange}
                    />
                  ))}
            </>
          )}
        </Box>
      </Box>
      <VestmapViewer
        open={openModal}
        setOpen={setOpenModal}
        vestmapData={currentVestmap}
        openSnackbar={openSnackbar}
        modifyVestmap={modifyVestmap}
      />
    </Box>
  );
}
