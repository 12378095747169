//import logo from './../../img/logo.png';
// import { ReactComponent as Logo } from './../../../public/Logo.svg';
import { React, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthProvider';
import RunReports from './RunReports';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <p className='loginScrenInner'>
      🌲 Santa Cruz, CA © {currentYear} VestMap Technology Inc.
    </p>
  );
}

const Home = () => {
  const { user, openLogin, openSignup, logout, closeChatHead } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    closeChatHead();
  }, []);

  if (user) {
    navigate('/run-reports');

    // return (
    //   <>
    //     <RunReports />
    //   </>
    // );
  } else {
    return (
      <div
        className='loginScren'
        style={{
          height: '100vh',
          backgroundColor: '#f9f8f7',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='loginScrenInner'>
          <img
            style={{
              width: '560px',
              maxWidth: '100%',
              maxHeight: '100%',
              height: '100%',
            }}
            src='Logo.svg'
          />
          <div className='loginScrenButtons'>
            <button className='loginScrenButtonLogin' onClick={openLogin}>
              Login
            </button>
            <button className='loginScrenButtonSignup' onClick={openSignup}>
              Create Account
            </button>
          </div>
          <Footer className='loginScrenInner' />
        </div>
      </div>
    );
  }
};

export default Home;
