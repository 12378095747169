import styles from './msa.module.css';
import React from 'react';
import globalStyles from '../globalStyles.module.css';
import { Skeleton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import Overlay from '../Overlay';

const Msa = ({ msa, address_info, map }) => {
  const msaAvailable =
    msa?.MSA_HAI_CY || msa?.MSA_POPGRWCYFY || msa?.MSA_WLTHINDXCY;

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [demoMapLoaded, setDemoMapLoaded] = useState(true);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {msaAvailable && (
        <>
          {demoMapLoaded && (
            <Skeleton
              variant='rectangular'
              sx={{
                height: '35rem',
                width: '100%',
              }}
              animation='wave'
            />
          )}
          <img
            src={map}
            className={globalStyles.mapContainer}
            alt='MSA Map'
            onLoad={() => setDemoMapLoaded(false)}
            style={{ display: demoMapLoaded ? 'none' : 'block' }}
          />
          <h4
            className={styles.heading}
            style={{
              ...updatedStyles.subsectionHeading,
              fontSize: '24px',
            }}
          >
            Metropolitan Statistical Area
          </h4>
          <h3>{msa?.NAME || 'N/A'}</h3>
          <div className={styles.sectionContainer}>
            <div className={globalStyles.fullRow}>
              <div
                className={`${globalStyles.grayBox}`}
                style={{
                  position: 'relative',
                }}
              >
                <h4>Housing Affordability Index</h4>
                <h3
                  style={{
                    fontWeight: '900',
                  }}
                >
                  {msa?.MSA_HAI_CY}
                </h3>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('Housing Affordability Index');
                    setIsOverlayVisible(true);
                  }}
                  style={{
                    ...updatedStyles.helpoutlineIconStyle,
                    paddingTop: '1rem',
                  }}
                />
                {activeOverlay === 'Housing Affordability Index' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'Housing Affordability Index'}
                    />
                  </div>
                )}
              </div>
              <div
                className={`${globalStyles.grayBox}`}
                style={{
                  position: 'relative',
                }}
              >
                <h4>Wealth Index</h4>
                <h3
                  style={{
                    fontWeight: '900',
                  }}
                >
                  {msa?.MSA_WLTHINDXCY}
                </h3>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('Wealth Index');
                    setIsOverlayVisible(true);
                  }}
                  style={{
                    ...updatedStyles.helpoutlineIconStyle,
                    paddingTop: '1rem',
                  }}
                />
                {activeOverlay === 'Wealth Index' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'Wealth Index'}
                    />
                  </div>
                )}
              </div>
              <div
                className={`${globalStyles.grayBox}`}
                style={{
                  position: 'relative',
                }}
              >
                <h4>Population Growth Rate</h4>
                <h3
                  style={{
                    fontWeight: '900',
                  }}
                >
                  {msa?.MSA_POPGRWCYFY}
                </h3>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('Population Growth Rate');
                    setIsOverlayVisible(true);
                  }}
                  style={{
                    ...updatedStyles.helpoutlineIconStyle,
                    paddingTop: '1rem',
                  }}
                />
                {activeOverlay === 'Population Growth Rate' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'Population Growth Rate'}
                    />
                  </div>
                )}
              </div>
              <div
                className={`${globalStyles.grayBox}`}
                style={{
                  position: 'relative',
                }}
              >
                <h4>Total Population</h4>
                <h3
                  style={{
                    fontWeight: '900',
                  }}
                >
                  {msa?.MSA_TOTPOP20?.toLocaleString('en-US')}
                </h3>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('Total Population');
                    setIsOverlayVisible(true);
                  }}
                  style={{
                    ...updatedStyles.helpoutlineIconStyle,
                    paddingTop: '1rem',
                  }}
                />
                {activeOverlay === 'Total Population' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'Total Population'}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className={globalStyles.fullRow}
              style={{ alignItems: 'flex-start' }}
            >
              <div className={styles.column}>
                <h2
                  style={{
                    position: 'relative',
                    ...updatedStyles.iconContainingDiv,
                  }}
                >
                  Business Diversification Index
                  <HelpOutlineIcon
                    onClick={() => {
                      handleQuestionMarkClick('Business Diversification Index');
                      setIsOverlayVisible(true);
                    }}
                    style={{
                      ...updatedStyles.helpoutlineIconStyle,
                    }}
                  />
                  {activeOverlay === 'Business Diversification Index' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '0%',
                        left: '0%',
                        width: '16rem',
                        zIndex: '1000',
                      }}
                    >
                      <Overlay
                        isVisible={isOverlayVisible}
                        onClose={() => {
                          setIsOverlayVisible(false);
                          handleCloseOverlay();
                        }}
                        keyElement={'Business Diversification Index'}
                      />
                    </div>
                  )}
                </h2>
                <h2
                  style={{
                    fontWeight: '900',
                    fontSize: '19.2px',
                  }}
                >
                  {msa?.MSA_NUM_BUSINESS_DIVERSIFICATION?.toLocaleString(
                    'en-US',
                  )}
                </h2>
                <h3
                  className={styles.columnHeader}
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontSize: '20.5px',
                  }}
                >
                  Top 5 Businesses by Sector
                </h3>
                <div>
                  <h4>2023 Total Businesses (NAICS)</h4>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_N01_BUS?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 Retail Trade Businesses (NAICS)</h4>
                  <h3>{msa?.MSA_N02_BUS?.toLocaleString('en-US')}</h3>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_BUSINESS_2?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 Other Service excl Pub Admin Bus (NAICS)</h4>
                  <h3>{msa?.MSA_N03_BUS?.toLocaleString('en-US')}</h3>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_BUSINESS_3?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 Prof/Scientific/Tech Service Bus (NAICS)</h4>
                  <h3>{msa?.MSA_N04_BUS?.toLocaleString('en-US')}</h3>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_BUSINESS_4?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 HealthCare/Social Assist Bus (NAICS)</h4>
                  <h3>{msa?.MSA_N05_BUS?.toLocaleString('en-US')}</h3>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_BUSINESS_5?.toLocaleString('en-US')}
                  </h3>
                </div>
              </div>
              <div className={styles.column}>
                <h2
                  style={{
                    position: 'relative',
                    ...updatedStyles.iconContainingDiv,
                  }}
                >
                  Employers Diversification Index
                  <HelpOutlineIcon
                    onClick={() => {
                      handleQuestionMarkClick(
                        'Employers Diversification Index',
                      );
                      setIsOverlayVisible(true);
                    }}
                    style={{
                      ...updatedStyles.helpoutlineIconStyle,
                    }}
                  />
                  {activeOverlay === 'Employers Diversification Index' && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '0%',
                        left: '0%',
                        width: '16rem',
                        zIndex: '1000',
                      }}
                    >
                      <Overlay
                        isVisible={isOverlayVisible}
                        onClose={() => {
                          setIsOverlayVisible(false);
                          handleCloseOverlay();
                        }}
                        keyElement={'Employers Diversification Index'}
                      />
                    </div>
                  )}
                </h2>
                <h2>
                  {msa?.MSA_NUM_EMP_DIVERSIFICATION?.toLocaleString('en-US')}
                </h2>
                <h3
                  className={styles.columnHeader}
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontSize: '20.5px',
                  }}
                >
                  Top 5 Employers by Sector
                </h3>
                <div>
                  <h4>2023 Total Employees (NAICS)</h4>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_EMP?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 HealthCare/Social Assist Emp (NAICS)</h4>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_EMP_2?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 Retail Trade Employees (NAICS)</h4>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_EMP_3?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 Prof/Scientific/Tech Service Emp (NAICS)</h4>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_EMP_4?.toLocaleString('en-US')}
                  </h3>
                </div>
                <div>
                  <h4>2023 Accommodation/Food Service Emp (NAICS)</h4>
                  <h3
                    style={{
                      fontWeight: '900',
                      fontSize: '19.2px',
                    }}
                  >
                    {msa?.MSA_NUM_EMP_5?.toLocaleString('en-US')}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!msaAvailable && (
        <div className={`${globalStyles.grayBox}`}>
          <h4>
            Sorry, this address does not fall within a metropolitan statistical
            area
          </h4>
        </div>
      )}
    </div>
  );
};

export default Msa;
