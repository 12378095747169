import React from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ReactComponent as ArrowsDark } from '@/../../public/arrowsdark.svg';

const customFontStyle = {
  color: '#212529',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  lineHeight: '150%',
  letterSpacing: '0.0125rem',
};

const tableHeadingStyle = {
  display: 'flex',
  color: '#004852',
  fontFamily: customFontStyle.fontFamily,
  fontSize: '1rem',
  fontStyle: customFontStyle.fontStyle,
  fontWeight: '800',
  lineHeight: '150%',
  letterSpacing: '0.0125rem',
};

function ListView({
  addresses,
  status,
  viewVestmap,
  changeListViewState,
  bulkName,
  handleProSearchesExpended,
}) {
  const [sortedData, setSortedData] = useState(addresses);
  const [sortOrder, setSortOrder] = useState(false); //true means asc and false means desc
  const [sortKey, setSortKey] = useState('');

  useEffect(() => {
    // console.log("Sorted Data", sortedData);
  }, [sortedData]);

  function sortData(currentKey) {
    let order;
    if (currentKey === sortKey) {
      order = !sortOrder;
      setSortOrder(prev => !prev);
    } else {
      setSortKey(currentKey);
      setSortOrder(true);
      order = true;
    }

    let sorted = [...sortedData];
    sorted.sort((a, b) => {
      if (order === true) {
        switch (currentKey) {
          case 'income_block':
            return compareValues(
              a.income_block_tract
                ?.annual_forecasted_median_household_income_growth_tract,
              b.income_block_tract
                ?.annual_forecasted_median_household_income_growth_tract,
            );
          case 'income_tract':
            return compareValues(
              a.income_block_tract
                ?.annual_forecasted_median_household_income_growth_zip,
              b.income_block_tract
                ?.annual_forecasted_median_household_income_growth_zip,
            );
          case 'crime':
            return compareValues(
              a.crime?.property_crime_index_score,
              b.crime?.property_crime_index_score,
            );
          case 'population_growth':
            return compareValues(
              a.expansion?.annual_forecasted_population_growth_zip,
              b.expansion?.annual_forecasted_population_growth_zip,
            );
          case 'schools':
            return compareValues(
              a.schools?.schools_nearest_3[0]?.rating,
              b.schools?.schools_nearest_3[0]?.rating,
            );
          case 'rent':
            return compareValues(
              a.rent?.rent_grid_median,
              b.rent?.rent_grid_median,
            );
          default:
            return 0;
        }
      } else {
        switch (currentKey) {
          case 'income_block':
            return compareValues(
              b.income_block_tract
                ?.annual_forecasted_median_household_income_growth_tract,
              a.income_block_tract
                ?.annual_forecasted_median_household_income_growth_tract,
            );
          case 'income_tract':
            return compareValues(
              b.income_block_tract
                ?.annual_forecasted_median_household_income_growth_zip,
              a.income_block_tract
                ?.annual_forecasted_median_household_income_growth_zip,
            );
          case 'crime':
            return compareValues(
              b.crime?.property_crime_index_score,
              a.crime?.property_crime_index_score,
            );
          case 'population_growth':
            return compareValues(
              b.expansion?.annual_forecasted_population_growth_zip,
              a.expansion?.annual_forecasted_population_growth_zip,
            );
          case 'schools':
            return compareValues(
              b.schools?.schools_nearest_3[0]?.rating,
              a.schools?.schools_nearest_3[0]?.rating,
            );
          case 'rent':
            return compareValues(
              b.rent?.rent_grid_median,
              a.rent?.rent_grid_median,
            );
          default:
            return 0;
        }
      }
    });

    console.log('Sorted');
    setSortedData(sorted);
  }

  function compareValues(valueA, valueB) {
    if (valueA < valueB) {
      return 1;
    }
    if (valueA > valueB) {
      return -1;
    }
    return 0;
  }

  return (
    <>
      <Grid
        container
        sx={{
          height: '4rem',
          borderTop: '1px solid #EDF2F7',
        }}
      >
        <Grid item xs={2.6} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>Address</Typography>
        </Grid>
        <Grid item xs={1.4} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>
            Income <br /> (Block)
          </Typography>
          <ArrowsDark
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              sortData('income_block');
            }}
          />
        </Grid>
        <Grid item xs={1.3} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>
            Income <br /> Growth
            <br />
            (Tract)
          </Typography>
          <ArrowsDark
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              sortData('income_tract');
            }}
          />
        </Grid>
        <Grid item xs={1.3} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>
            Crime <br />
            (Overall)
          </Typography>
          <ArrowsDark
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              sortData('crime');
            }}
          />
        </Grid>
        <Grid item xs={1.3} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>
            Population
            <br /> Growth <br />
            (Zip)
          </Typography>
          <ArrowsDark
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              sortData('population_growth');
            }}
          />
        </Grid>
        <Grid item xs={1.1} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>
            Schools
            <br />
            (Best
            <br /> Rating)
          </Typography>
          <ArrowsDark
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              sortData('schools');
            }}
          />
        </Grid>
        <Grid item xs={1.4} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>
            Rent
            <br /> (Median)
          </Typography>
          <ArrowsDark
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              sortData('rent');
            }}
          />
        </Grid>
        <Grid item xs={1.6} display={'flex'} alignItems={'center'} gap={1}>
          <Typography sx={[tableHeadingStyle]}>Report</Typography>
        </Grid>
      </Grid>

      <Grid container sx={{}}>
        {sortedData.map((addressData, index) => {
          return (
            <Grid
              container
              sx={{
                height: '5rem',
                borderTop: '1px solid #EDF2F7',

                borderBottom:
                  index === sortedData.length - 1
                    ? '1px solid #EDF2F7'
                    : 'none',
              }}
            >
              <Grid
                item
                xs={2.6}
                display={'flex'}
                alignItems={'center'}
                gap={1}
                paddingRight={3}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontSize: '0.875rem',
                      textAlign: 'left',
                      fontWeight: '400',
                      letterSpacing: '0',
                    },
                  ]}
                >
                  {addressData?.address}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.4}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontWeight: '400',
                      fontSize: '1rem',
                    },
                  ]}
                >
                  {addressData?.income_block_tract
                    ?.median_household_income_block1
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(
                        addressData?.income_block_tract
                          ?.median_household_income_block1,
                      )
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.3}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontWeight: '400',
                      fontSize: '1rem',
                    },
                  ]}
                >
                  {addressData?.income_block_tract
                    ?.annual_forecasted_median_household_income_growth_tract
                    ? addressData?.income_block_tract
                        ?.annual_forecasted_median_household_income_growth_tract +
                      ' %'
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.3}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontWeight: '400',
                      fontSize: '1rem',
                    },
                  ]}
                >
                  {addressData?.crime?.property_crime_index_score
                    ? addressData?.crime?.property_crime_index_score
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.3}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontWeight: '600',
                      fontSize: '1rem',
                      color: '#024B53',
                    },
                  ]}
                >
                  {addressData?.expansion
                    ?.annual_forecasted_population_growth_zip
                    ? addressData?.expansion
                        ?.annual_forecasted_population_growth_zip + ' %'
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.1}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontWeight: '600',
                      fontSize: '1rem',
                      color: '#024B53',
                    },
                  ]}
                >
                  {addressData?.schools?.schools_nearest_3[0]?.rating
                    ? addressData?.schools?.schools_nearest_3[0]?.rating
                    : 'N/A'}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1.4}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Typography
                  sx={[
                    customFontStyle,
                    {
                      fontWeight: '600',
                      fontSize: '1rem',
                      color: '#024B53',
                    },
                  ]}
                >
                  {addressData?.rent?.rent_grid_median
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(addressData?.rent?.rent_grid_median)
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1.6}
                display={'flex'}
                alignItems={'center'}
                gap={1}
              >
                <Button
                  sx={[
                    customFontStyle,
                    {
                      bgcolor: '#39585C',
                      fontWeight: '800',
                      fontSize: '0.75rem',
                      height: '2.5rem',
                      borderRadius: '0.5rem',
                      color: '#fff',
                      textTransform: 'uppercase',
                      ':hover': {
                        bgcolor: '#39585C',
                        opacity: [0.9, 0.8, 0.7],
                      },
                    },
                    {
                      visibility:
                        addressData?.status === 'completed'
                          ? 'visible'
                          : 'hidden',
                    },
                  ]}
                  onClick={() => {
                    addressData?.status === 'completed' &&
                      viewVestmap(addressData);
                  }}
                >
                  {addressData?.status === 'completed' && 'View Report'}
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default ListView;
