import styles from './rent.module.css';
import globalStyles from '../globalStyles.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import Overlay from '../Overlay';
import { Box } from '@mui/material';

const Rent = ({ rent, address_info, bedrooms }) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [activeOverlay, setActiveOverlay] = useState(null);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  return (
    <Box className={globalStyles.container} onClick={closeOutsideClick}>
      <div className={styles.sectionContainer}>
        <Box
          sx={{
            height: '4rem',
          }}
        ></Box>
        <Box
          sx={{
            ...updatedStyles.subsectionHeading,
            fontSize: '24px',
            '@media (max-width: 600px)': {
              lineHeight: '1.1',
            },
          }}
        >
          {rent?.rent_grid_title}
        </Box>
        <Box
          sx={{
            fontSize: '19.2px',
            fontWeight: '700',
            lineHeight: '20px',
            textAlign: 'center',

            '@media (max-width: 600px)': {
              lineHeight: '1.1',
            },
          }}
        >
          Results based on {bedrooms} bedroom rentals seen within 12 months in a
          1.5 mile radius
        </Box>
        <p>
          Rent from
          <a
            target={'_blank'}
            href={rent?.rent_grid_quickview_url}
            rel='noreferrer'
            style={{
              fontWeight: '500',
              color: '#4FA490',
            }}
          >
            {' '}
            Rent-O-Meter
          </a>
        </p>
        <div className={styles.colContainer}>
          <div
            className={globalStyles.grayBox}
            style={{
              position: 'relative',
            }}
          >
            <h4>AVERAGE</h4>
            <h3
              style={{
                fontWeight: '900',
                color: 'black',
              }}
            >
              {USDollar.format(rent?.rent_grid_average || 0)}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Rent Average');
                setIsOverlayVisible(true);
              }}
              style={{
                ...updatedStyles.helpoutlineIconStyle,
                paddingTop: '1rem',
              }}
            />
            {activeOverlay === 'Rent Average' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Rent Average'}
                />
              </div>
            )}
          </div>
          <div
            className={globalStyles.grayBox}
            style={{
              position: 'relative',
            }}
          >
            <h4>MEDIAN</h4>
            <h3
              style={{
                fontWeight: '900',
                color: 'black',
              }}
            >
              {USDollar.format(rent?.rent_grid_median || 0)}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Rent Median');
                setIsOverlayVisible(true);
              }}
              style={{
                ...updatedStyles.helpoutlineIconStyle,
                paddingTop: '1rem',
              }}
            />
            {activeOverlay === 'Rent Median' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Rent Median'}
                />
              </div>
            )}
          </div>
          <div
            className={globalStyles.grayBox}
            style={{
              position: 'relative',
            }}
          >
            <h4>25 ~ PERCENTILE</h4>
            <h3
              style={{
                fontWeight: '900',
                color: 'black',
              }}
            >
              {USDollar.format(rent?.rent_grid_percentile_25 || 0)}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Rent 25 Percentile');
                setIsOverlayVisible(true);
              }}
              style={{
                ...updatedStyles.helpoutlineIconStyle,
                paddingTop: '1rem',
              }}
            />
            {activeOverlay === 'Rent 25 Percentile' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Rent 25 Percentile'}
                />
              </div>
            )}
          </div>
          <div
            className={globalStyles.grayBox}
            style={{
              position: 'relative',
            }}
          >
            <h4>75 ~ PERCENTILE</h4>
            <h3
              style={{
                fontWeight: '900',
                color: 'black',
              }}
            >
              {USDollar.format(rent?.rent_grid_percentile_75 || 0)}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Rent 75 Percentile');
                setIsOverlayVisible(true);
              }}
              style={{
                ...updatedStyles.helpoutlineIconStyle,
                paddingTop: '1rem',
              }}
            />
            {activeOverlay === 'Rent 75 Percentile' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Rent 75 Percentile'}
                />
              </div>
            )}
          </div>
        </div>
        <Box
          sx={{
            height: '7rem',
          }}
        ></Box>
      </div>
    </Box>
  );
};

export default Rent;
