import { useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const PlaceComponent = () => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: 'us' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options,
    );
  }, []);
  return (
    <TextField
      inputRef={inputRef}
      id='address'
      placeholder='Enter a Valid Street Address'
      variant='outlined'
      sx={{
        width: '100%',
        color: '#212529',
        input: {
          '&::placeholder': {
            color: '#212529',
            opacity: 2,
            fontWeight: 800,
            fontSize: '0.875rem',
          },
        },
      }}
      InputProps={{
        sx: {
          color: '#212529',
          borderRadius: '1rem',
        },

        startAdornment: (
          <InputAdornment>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default PlaceComponent;
