// * This is the main sidebar
import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box, Divider, Typography } from '@mui/material';
import { ReactComponent as VestmapLogo } from './../../img/vestmaplogo.svg';

// Run Report Icon
import { ReactComponent as HomeIconLight } from '@/../../public/homeiconlight.svg';
import { ReactComponent as HomeIconDark } from '@/../../public/homeicondark.svg';

// Bulk Icon
import { ReactComponent as BulkIconDark } from '@/../../public/bulkicondark.svg';
import { ReactComponent as BulkIconLight } from '@/../../public/bulkiconlight.svg';

// Learn Icon
import { ReactComponent as LearnIcon } from '@/../../public/learnicondark.svg';

// Explore Icon
import { ReactComponent as ExploreIconDark } from '@/../../public/msaIconDark.svg';
import { ReactComponent as ExploreIconLight } from '@/../../public/msaIconLight.svg';

// DropDown Icon
import { ReactComponent as DropDown } from '@/../../public/dropdowndark.svg';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = {
  selected: {
    backgroundColor: '#39585C',
    color: '#FFFFFF',
  },

  unselected: {
    color: '#39585C',
    fontWeight: 400,
    fontStyle: 'normal',
  },

  itemBoxStyle: {
    display: 'flex',
    height: '1.1rem',
    padding: '1rem',
    alignItems: 'center',
    gap: '0.75rem',
    alignSelf: 'stretch',
    borderRadius: '0.75rem',
  },

  itemTextStyle: {
    fontFamily: '"Lato", sans-serif',

    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 800,
    lineHeight: '150%',
    letterSpacing: '0.0125rem',
  },
};

const RunReportsSidebar = ({ mobileView }) => {
  const [admin, setAdmin] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('mongodbUser'));
    if (user?.admin === 'true') setAdmin(true);
    else {
      const admin_emails = ['Clay@vestmap.com'];
      if (admin_emails.includes(user?.email)) {
        setAdmin(true);
      }
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        height: '100%',
        backgroundColor: '#F9F9F9',
        flexShrink: 0,
        display: {
          xs: 'none',
          sm: 'flex',
        },
        flexDirection: 'column',
        alignItems: 'center',
        padding: '1.25rem',
        gap: '2.2rem',
        boxShadow: {
          xs: 'none',
          sm: '0px 4px 4px 0px #00000040',
        },
      }}
    >
      <Box sx={{ display: 'flex', paddingTop: '0.75rem' }}>
        <VestmapLogo
          style={{
            width: '15.25rem',
            maxWidth: '100%',
            height: '1.95438rem',
            flexShrink: 0,
          }}
        />
      </Box>

      <Divider
        sx={{
          width: '13.3rem',
          maxWidth: '100%',
          // width: {
          //   xs: '100%',
          //   md: '13.3rem',
          // },
          height: '0.0625rem',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1rem',
          width: '13.625rem',
          // width: {
          //   xs: '100%',
          //   md: '13.3rem',
          // },
          maxWidth: '100%',

          '@media (max-width: 600px)': {
            transform: 'translateX(-200%)',
            width: ' 100%',
            boxShadow: ' 0 0 10px 5px #efefef',
            textAlign: ' center',
            transition: ' all 0.5s linear',
          },
        }}
      >
        <Link to='/run-reports' style={{ width: '100%' }}>
          <Box
            sx={[
              styles.itemBoxStyle,
              location.pathname === '/run-reports'
                ? styles.selected
                : styles.unselected,
            ]}
          >
            {location.pathname === '/run-reports' ? (
              <HomeIconLight
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            ) : (
              <HomeIconDark
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            )}

            <Typography
              sx={[
                styles.itemTextStyle,
                {
                  color:
                    location.pathname === '/run-reports' ||
                    location.pathname === '/'
                      ? styles.selected.color
                      : styles.unselected.color,
                  fontWeight:
                    location.pathname === '/run-reports' ||
                    location.pathname === '/'
                      ? 800
                      : 400,
                },
              ]}
            >
              Run Reports
            </Typography>
          </Box>
        </Link>

        {/* <Link to='/bulk-import' style={{ width: '100%' }}>
          <Box
            sx={[
              styles.itemBoxStyle,

              location.pathname === '/bulk-import'
                ? styles.selected
                : styles.unselected,
            ]}
          >
            {location.pathname === '/bulk-import' ? (
              <BulkIconLight
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            ) : (
              <BulkIconDark
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              />
            )}

            <Typography
              sx={[
                styles.itemTextStyle,
                {
                  color:
                    location.pathname === '/bulk-import'
                      ? styles.selected.color
                      : styles.unselected.color,

                  fontWeight: location.pathname === '/bulk-import' ? 800 : 400,
                },
              ]}
            >
              Bulk Imports
            </Typography>
          </Box>
        </Link> */}

        <NestedAccordion currentPage={location.pathname} />

        <Box
          sx={[
            styles.itemBoxStyle,
            styles.unselected,
            {
              cursor: 'pointer',
            },
          ]}
          onClick={() => {
            window.open(
              'https://vestmap.notion.site/VestMap-Data-Dictionary-01d8ebd075af4bea9c7ad0cb6dff6698',
              '_blank',
            );
          }}
        >
          <LearnIcon
            style={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />

          <Typography
            sx={[
              styles.itemTextStyle,
              {
                color: styles.unselected.color,
              },
            ]}
          >
            Learn to Use VestMap
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const NestedAccordion = ({ currentPage }) => (
  <div style={{ width: '100%' }}>
    <Accordion
      disableGutters
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: currentPage === '/zipfinder' ? '#FFFFFF' : '#39585C',
            }}
          />
        }
        aria-controls='panel1a-content'
        id='panel1a-header'
        sx={[
          styles.itemBoxStyle,
          currentPage === '/zipfinder' ? styles.selected : styles.unselected,
        ]}
      >
        {currentPage === '/zipfinder' ? (
          <ExploreIconLight
            style={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        ) : (
          <ExploreIconDark
            style={{
              width: '1.25rem',
              height: '1.25rem',
            }}
          />
        )}
        <Typography
          sx={[
            styles.itemTextStyle,
            {
              color:
                currentPage === '/zipfinder'
                  ? styles.selected.color
                  : styles.unselected.color,
              marginLeft: '8%',
              fontWeight: currentPage === '/zipfinder' ? 800 : 400,
            },
          ]}
        >
          Explore Markets
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ borderLeft: '2px solid grey' }}>
          <Link to='/zipfinder'>
            <ListItem>
              <ListItemText
                disableTypography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '0.875rem',
                  fontStyle: 'normal',
                  fontWeight: currentPage === '/zipfinder' ? 800 : 500,
                  lineHeight: '150%',
                  letterSpacing: '0.0125rem',
                  color: currentPage === '/zipfinder' ? '#39585C' : '#000000',
                  cursor: 'pointer',
                }}
                primary='ZipFinder'
              />
            </ListItem>
          </Link>
          {/* <ListItem>
            <ListItemText
              disableTypography
              sx={{
                fontFamily: 'Lato',
                fontSize: '0.875rem',
                fontStyle: 'normal',
                lineHeight: '150%',
                letterSpacing: '0.0125rem',
                fontWeight: 700,
              }}
              primary='Other Source'
            />
          </ListItem> */}
        </List>
      </AccordionDetails>
    </Accordion>
  </div>
);

export default RunReportsSidebar;
