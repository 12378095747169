import React, { useEffect } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { useState } from 'react';
import './bulk.module.css';
import { ReactComponent as DeleteLoader } from '@/../../public/deleteloader.svg';

const customFontStyle = {
  color: '#39585C',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  lineHeight: '150%',
  letterSpacing: '0.0125rem',
};

function BulkView({
  date,
  groupName,
  status,
  addresses,
  deleteBulk,
  bulkID,
  handleListView,
}) {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    console.log(loader);
  }, [loader]);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  function handleDelete(e) {
    e.stopPropagation();
    setLoader(true);
    const url = process.env.REACT_APP_NODE_URL + `/bulk-vestmap/deleteBulk`;
    const data = {
      bulkID: bulkID,
    };
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Something went wrong on api server!');
        }
      })
      .then(response => {
        console.debug(response);
        setLoader(false);
        deleteBulk(bulkID);
      })
      .catch(error => {
        setLoader(false);
        console.error(error);
      });
  }

  return (
    <>
      <Grid
        container
        sx={{
          height: '3.8rem',
          borderTop: '1px solid #EDF2F7',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleListView(true, addresses, groupName, status);
        }}
      >
        <Grid item xs={2} display={'flex'} alignItems={'center'} gap={1}>
          <Typography
            sx={[
              customFontStyle,
              {
                fontSize: '0.875rem',
                fontWeight: 400,
                color: '#718096',
              },
            ]}
          >
            {formatDate(date)}
          </Typography>
        </Grid>
        <Grid item xs={3} display={'flex'} alignItems={'center'} gap={1}>
          <Typography
            sx={[
              customFontStyle,
              {
                fontSize: '1rem',
                fontWeight: 800,
                color: '#1A202C',
              },
            ]}
          >
            {groupName}
          </Typography>
        </Grid>
        <Grid item xs={2.5} display={'flex'} alignItems={'center'} gap={1}>
          <Typography
            sx={[
              customFontStyle,
              {
                fontSize: '1rem',
                fontWeight: 600,
                color: '#024B53',
              },
            ]}
          >
            {addresses.length}
          </Typography>
        </Grid>
        <Grid item xs={2.5} display={'flex'} alignItems={'center'} gap={1}>
          <Typography
            sx={[
              customFontStyle,
              {
                fontSize: '1.0625rem',
                fontWeight: 700,
                color: status === 'Completed' ? '#4FA490' : 'Black',
              },
            ]}
          >
            {status === 'Error' ? 'Failed' : status}
          </Typography>
        </Grid>
        <Grid item xs={2} display={'flex'} alignItems={'center'} gap={1}>
          <Box
            sx={{
              border: '1px solid rgba(0, 72, 82, 0.35)',
              borderRadius: '0.5rem',
              display: 'flex',
              width: '6rem',
              height: '2.5rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleDelete}
          >
            <Typography
              sx={[
                customFontStyle,
                {
                  fontSize: '0.875rem',
                  fontWeight: 800,
                  color: '#1A202C',
                  cursor: 'pointer',
                },
              ]}
            >
              {loader ? <DeleteLoader height={30} width={40} /> : 'Delete'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default BulkView;
