import React from 'react';

import Box from '@mui/material/Box';

import Header from '../layout/Header';

const crimeExplore = () => {
  return (
    <>
      <Header />
      <Box className='Dashboard'>
        {/* <div className="Dashboard--main"> */}
        {/* <div className="Dashboard--wrapper"> */}
        {/* <div className="Dashboard--inner"> */}
        <div className='Dashboard--grid'>
          <div className='Dashboard--item Dashboard--item--sidebar'>
            {/* <Sidebar /> */}
          </div>
          <div
            className='Dashboard--item Dashboard--item--content'
            // style={{ padding: "20px 101px 68px 53px" }}
          >
            <h1 style={{ fontSize: '24px' }}>Crime Explorer</h1>
            <iframe
              style={{
                width: '100%',
                height: '100vh',
                frameborder: '0',
                marginwidth: '0',
                marginheight: '0',
                scrolling: 'no',
              }}
              title='Solid Waste Services Map'
              src='https://leeripma1.maps.arcgis.com/apps/webappviewer/index.html?id=43213fe75ed849eeae800bd39bb30af6'
            ></iframe>
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </Box>
    </>
  );
};

export default crimeExplore;
