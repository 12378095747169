import React from 'react';

const ColoredSquare = ({ color }) => {
  return (
    <div
      style={{
        width: '15%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        marginRight: '3%',
        marginBottom: '1.5%',
      }}
    >
      <svg
        aria-labelledby='Preview for >  100,000'
        focusable='false'
        height='15'
        role='img'
        style={{ display: 'block' }}
        width='15'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs></defs>
        <g transform='matrix(1.1219512224197388,0,0,1.1219512224197388,11.5,11.5)'>
          <path
            d='M -10,-10 L 10,0 L 10,10 L -10,10 L -10,-10 Z'
            fill={`rgba(${color})`}
            fillRule='evenodd'
            stroke='rgba(214, 215, 214, 1)' // #d6d7d6
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeMiterlimit='4'
            strokeWidth='1'
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default ColoredSquare;
