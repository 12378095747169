import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import Header from '../layout/Header';
import Content from '../Content';

import { useAuth } from '../../AuthProvider';

const RunReports = () => {
  const { user } = useAuth();
  const [userId, setUserId] = useState('');

  const fetchUser = async outsettaID => {
    console.log({ outsettaID });
    try {
      const url = new URL(
        process.env.REACT_APP_NODE_URL + '/user/' + outsettaID,
      );
      const response = await fetch(url);
      const data = await response.json();
      const { user } = data;
      localStorage.setItem('mongodbUser', JSON.stringify(user));
      setUserId(user._id);
      // setMongodbUser(user);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    console.log({ user });
    const outsettaUserID = user?.Uid;
    fetchUser(outsettaUserID);
  }, []);

  if (!userId) return null;
  return <Content userId={userId} />;
};

export default RunReports;
